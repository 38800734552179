import { navigate } from "gatsby"
import React, { Component } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Loading from "../components/ui/Loading"
import axios from "axios"
import TwoColTextImage from "../components/content/TwoColTextImage"

import firstImage from "../images/Brunch8.jpg"
import secondImage from "../images/allbaronelogo.png"
import thirdImage from "../images/VI-Date-Night-Steak-Seasbass.jpg"
import Button from "../components/ui/Button"

class Validate extends Component {
  constructor(props) {
    // eslint-disable-line
    super(props)

    this.state = {
      loading: false,
      hasClicked: false,
      success: false,
      sending: false,
    }

    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    // Fetch the URL params
    const params = new URLSearchParams(this.props.location.search)
    const uuid = params.get("u")
    const key = params.get("k")

    // Check we have UUID and Key
    if (!uuid || !key) {
      navigate("/404")
    }
  }

  validate() {
    if (this.state.sending) return false

    this.setState(
      {
        sending: true,
      },
      () => {
        // API Request
        const data = new FormData()
        const params = new URLSearchParams(this.props.location.search)
        const urlUuid = params.get("u")
        const urlKey = params.get("k")

        data.append("uuid", urlUuid)
        data.append("key", urlKey)

        axios({
          method: "post",
          url: `${process.env.GATSBY_API_ENDPOINT}/api/validate`,
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        })
          .then(res => {
            if (res.data.success !== true) {
              this.setState({
                success: false,
                loading: false,
                hasClicked: true,
                sending: false
              })
            } else {
              this.setState({
                success: true,
                loading: false,
                hasClicked: true,
                sending: false
              })
            }
          })
          .catch(() => {
            this.setState({
              success: false,
              loading: false,
              hasClicked: true,
              sending: false
            })
          })
      }
    )
  }

  render() {
    const images = [
      <img
        src={firstImage}
        alt="All Bar One Brunch"
        className={`aspect-[248/441] object-cover rounded-2xl w-[26.25%] max-w-[26.25%] relative`}
      />,
      <img
        src={secondImage}
        alt="All Bar One Logo"
        className={`aspect-[382/679] object-cover rounded-2xl mb-8 w-[40.75%] max-w-[40.75%] relative`}
      />,
      <img
        src={thirdImage}
        alt="Vintage Inns Food"
        className={`aspect-[281/500] object-cover rounded-2xl mb-16 w-[30%] max-w-[30%] relative`}
      />,
    ]

    return (
      <Layout home={true}>
        <Seo title="Register - Thank you - You&#39;re in " description="" />
        <div className={`container`}>
          {this.state.loading || !this.state.hasClicked ? (
            <div
              className={`flex min-h-[calc(100vh_-_225px)] w-full items-center justify-center`}
            >
              {this.state.loading && <Loading />}

              {!this.state.hasClicked && (
                <div>
                  <TwoColTextImage
                    title=""
                    content={
                      <div>
                        <p>
                          Please click the below link to complete your account
                          verification.
                        </p>
                        <Button
                          onClick={this.validate}
                          text={`Verify my account`}
                        />
                      </div>
                    }
                    images={images}
                    showAppLinks={false}
                  />
                </div>
              )}
            </div>
          ) : this.state.success ? (
            <TwoColTextImage
              title="You&#39;re In!"
              content={
                <p>
                  Use the links below to download our exclusive Supplier Dine
                  With Us app and receive your unique discount code!
                </p>
              }
              images={images}
              showAppLinks={true}
            />
          ) : (
            <TwoColTextImage
              title="Error"
              content={
                <p>
                  The link you have clicked is invalid. This may have expired.
                </p>
              }
              images={images}
              showAppLinks={false}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default Validate
